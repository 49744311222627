import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Table, Input } from 'reactstrap';
import { doc, updateDoc, arrayUnion, arrayRemove, setDoc, onSnapshot, getDoc } from 'firebase/firestore';
import { getStorage, ref, listAll, getDownloadURL, uploadBytesResumable, deleteObject } from "firebase/storage";
import { db } from './firebase';
import './PlacesTable.css';

function PlacesTable({ places: placesProp, foods, beverages, features, establishmentTypes, mainCuisines, subCuisines }) {
  const [places, setPlaces] = useState(placesProp);
  const [updatedNames, setUpdatedNames] = useState({});
  const [newItems, setNewItems] = useState({});
  const [servicesOrder, setServicesOrder] = useState([]);
  const [unsubscribeFuncs, setUnsubscribeFuncs] = useState({});
  const [images, setImages] = useState({});
  const [uploadingImage, setUploadingImage] = useState({});
  const [uploadProgress, setUploadProgress] = useState({});
  const [inputKey, setInputKey] = useState(Date.now());

  useEffect(() => {
    setPlaces(placesProp);
  }, [placesProp]);

  // Add this function to handle image deletion.
  const handleDeleteImage = async (placeId, url) => {
    const storage = getStorage();

    // Extract the image name from the URL
    const decodedUrl = decodeURIComponent(url);
    const imageName = decodedUrl.split('/').pop().split('?')[0];

    const imageRef = ref(storage, `places/${placeId}/${imageName}`);  // create a reference to the file to delete

    // Show a confirmation dialog to the user
    if (window.confirm('Are you sure you want to delete this image?')) {
      deleteObject(imageRef).then(() => {
        // File deleted successfully
        console.log('Image deleted successfully!');
        setImages(prevImages => {
          const newImages = { ...prevImages };
          newImages[placeId] = newImages[placeId].filter(imageUrl => imageUrl !== url);
          return newImages;
        });


        // Remove the picture's name from the picturesURL array in Firestore
        const placeRef = doc(db, 'places', placeId);
        updateDoc(placeRef, { picturesUrl: arrayRemove(imageName) });
      }).catch((error) => {
        // Uh-oh, an error occurred!
        console.error('Error deleting image:', error);
      });
    }
  };


  // Add this useEffect to fetch images when the component mounts.
  useEffect(() => {
    const fetchImages = async () => {
      const storage = getStorage();
      places.forEach(place => {
        const listRef = ref(storage, `places/${place.id}`);  // use the id of each place
        // Find all the prefixes and items.
        listAll(listRef)
          .then((res) => {
            const imagePromises = res.items.map((item) => getDownloadURL(item));

            // Wait for all URLs to be fetched.
            Promise.all(imagePromises).then((urls) => {
              setImages(prevImages => ({
                ...prevImages,
                [place.id]: urls,
              }));
            });
          })
          .catch((error) => {
            // Uh-oh, an error occurred!
            console.error("Error fetching images:", error);
          });
      });
    };

    fetchImages();
  }, [places]);  // update the dependencies to include places

  // Add this function to handle file uploads.
  const handleFileChange = async (e, placeId) => {
    const file = e.target.files[0];
    if (file) {
      setUploadingImage(prevUploadingImage => ({
        ...prevUploadingImage,
        [placeId]: true,
      }));
      const storage = getStorage();
      const storageRef = ref(storage, `places/${placeId}/${file.name}`);  // use the id of each place

      const uploadTask = uploadBytesResumable(storageRef, file);

      uploadTask.on('state_changed',
        (snapshot) => {
          // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
          var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          setUploadProgress(prevUploadProgress => ({
            ...prevUploadProgress,
            [placeId]: progress,
          }));
        },
        (error) => {
          console.log('Upload failed:', error);
          setUploadingImage(prevUploadingImage => ({
            ...prevUploadingImage,
            [placeId]: false,
          }));
          setInputKey(Date.now());  // Add this line
        },
        () => {
          // Upload completed successfully, now we can get the download URL
          getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
            setImages((prevImages) => ({
              ...prevImages,
              [placeId]: [...(prevImages[placeId] || []), downloadURL],
            }));
            setUploadProgress(prevUploadProgress => ({
              ...prevUploadProgress,
              [placeId]: null,  // reset progress
            }));
            setUploadingImage(prevUploadingImage => ({
              ...prevUploadingImage,
              [placeId]: false,
            }));
            setInputKey(Date.now());

            // Add the picture's name to the picturesURL array in Firestore
            const placeRef = doc(db, 'places', placeId);
            updateDoc(placeRef, { picturesUrl: arrayUnion(file.name) });
          });
        }
      );
    }
  };

  useEffect(() => {
    const newServicesOrder = [];
    const newUnsubscribeFuncs = { ...unsubscribeFuncs };

    places.forEach(place => {
      Object.keys(place.services || {}).forEach(key => {
        if (!newServicesOrder.includes(key)) {
          newServicesOrder.push(key);
        }
      });

      if (!newUnsubscribeFuncs[place.id]) {
        const placeRef = doc(db, 'places', place.id);
        newUnsubscribeFuncs[place.id] = onSnapshot(placeRef, (doc) => {
          if (doc.exists()) {
            fetchPlaceData(doc.id);
          }
        });
      }
    });

    // Clean up listeners for places that are no longer in the list
    Object.keys(unsubscribeFuncs).forEach(placeId => {
      if (!places.find(place => place.id === placeId)) {
        unsubscribeFuncs[placeId]();
        delete newUnsubscribeFuncs[placeId];
      }
    });

    setServicesOrder(newServicesOrder.sort());
    setUnsubscribeFuncs(newUnsubscribeFuncs);
  }, [places]);

  useEffect(() => {
    return () => {
      Object.values(unsubscribeFuncs).forEach(unsubscribe => unsubscribe());
    };
  }, [unsubscribeFuncs]);

  const handleUpdateName = async (placeId) => {
    try {
      const placeRef = doc(db, 'places', placeId);
      await updateDoc(placeRef, { name: updatedNames[placeId] });
      console.log('Place name updated successfully!');
    } catch (error) {
      console.error('Error updating place name: ', error);
    }
  };

  const handleChangeName = (placeId, newName) => {
    setUpdatedNames({ ...updatedNames, [placeId]: newName });
  };

  const fetchPlaceData = async (placeId) => {
    try {
      const placeRef = doc(db, 'places', placeId);
      const placeDoc = await getDoc(placeRef);
      const updatedPlace = { id: placeDoc.id, ...placeDoc.data() };
      setPlaces(prevPlaces => prevPlaces.map(place => place.id === placeId ? updatedPlace : place));
    } catch (error) {
      console.error(`Error fetching place ${placeId}: `, error);
    }
  };

  const handleAddItem = async (placeId, type) => {
    try {
      if (type === 'styles' || type === 'meals' || type === 'goodFors' || type === 'moods') {
        type = 'features'; // if it's a style or a meal, it still goes under the features key
      }
      const placeRef = doc(db, 'places', placeId);
      await updateDoc(placeRef, { [type]: arrayUnion(newItems[type][placeId]) });
      console.log(`Item added successfully to ${type}!`);
      setNewItems(prevState => ({ ...prevState, [type]: { ...prevState[type], [placeId]: '' } }));
      fetchPlaceData(placeId); // fetch updated place data
    } catch (error) {
      console.error(`Error adding item to ${type}: `, error);
    }
  };

  const handleRemoveItem = async (placeId, type, item) => {
    try {
      const placeRef = doc(db, 'places', placeId);
      await updateDoc(placeRef, { [type]: arrayRemove(item) });
      console.log(`Item removed successfully from ${type}!`);
      fetchPlaceData(placeId); // fetch updated place data
    } catch (error) {
      console.error(`Error removing item from ${type}: `, error);
    }
  };

  const handleChangeNewItem = (placeId, type, newItem) => {
    if (type === 'styles' || type === 'meals' || type === 'goodFors' || type === 'moods') {
      type = 'features'; // if it's a style or a meal, it still goes under the features key
    }
    setNewItems(prevState => ({ ...prevState, [type]: { ...prevState[type], [placeId]: newItem } }));
  };

  const handleToggleService = async (placeId, service) => {
    const newServices = { ...places.find(place => place.id === placeId).services };
    newServices[service] = !newServices[service];

    try {
      const placeRef = doc(db, 'places', placeId);
      await setDoc(placeRef, { services: newServices }, { merge: true });
      fetchPlaceData(placeId);
    } catch (error) {
      console.error('Error toggling service: ', error);
    }
  };

  const handleToggleDietRestriction = async (placeId, diet) => {
    const newDiets = { ...places.find(place => place.id === placeId).dietaryRestrictions };
    newDiets[diet] = !newDiets[diet];

    try {
      const placeRef = doc(db, 'places', placeId);
      await setDoc(placeRef, { dietaryRestrictions: newDiets }, { merge: true });
      fetchPlaceData(placeId);
    } catch (error) {
      console.error('Error toggling diet restriction: ', error);
    }
  };

  const foodsOptions = foods.map((food) => ({ value: food.id, name: food.id }));
  const beveragesOptions = beverages.map((beverage) => ({ value: beverage.id, name: beverage.id }));
  const featuresOptions = features.map((feature) => ({ value: feature.id, name: feature.id, key: feature.key }));
  const establishmentTypesOptions = establishmentTypes.map((establishmentType) => ({ value: establishmentType.id, name: establishmentType.id }));
  const mainCuisinesOptions = mainCuisines.map((mainCuisine) => ({ value: mainCuisine.id, name: mainCuisine.id }));
  const subCuisinesOptions = subCuisines.map((subCuisine) => ({ value: subCuisine.id, name: subCuisine.id }));

  return (
    <Table striped bordered responsive className="places-table">
      <thead>
        <tr>
          <th>ID</th>
          <th>Name</th>
          <th>Beverages</th>
          <th>Add Beverage</th>
          <th>Establishment Types</th>
          <th>Add Establishment Type</th>
          <th>Features [Features]</th>
          <th>Add Feature [Feature]</th>
          <th>Styles [Features]</th>
          <th>Add Style [Feature]</th>
          <th>GoodFors [Features]</th>
          <th>Add GoodFor [Feature]</th>
          <th>Moods [Features]</th>
          <th>Add Mood [Feature]</th>
          <th>Meals [Features]</th>
          <th>Add Meal [Feature]</th>
          <th>Foods</th>
          <th>Add Food</th>
          <th>Main Cuisines</th>
          <th>Add Main Cuisine</th>
          <th>Sub Cuisines</th>
          <th>Add Sub Cuisine</th>
          <th>Services</th>
          <th>Dietary Restrictions</th>
          <th>Pictures</th>
          <th>Add Picture</th>
        </tr>
      </thead>
      <tbody>
        {places.map((place) => {
          const { id, name, beverages, dietaryRestrictions, establishmentTypes, features, foods, mainCuisines, services, subCuisines } = place;
          const newName = updatedNames[id] !== undefined ? updatedNames[id] : name;
          const newBeverage = newItems['beverages'] ? newItems['beverages'][id] : '';
          const newFeature = newItems['features'] ? newItems['features'][id] : '';
          const newStyle = newItems['features'] ? newItems['features'][id] : '';
          const newGoodFor = newItems['features'] ? newItems['features'][id] : '';
          const newMood = newItems['features'] ? newItems['features'][id] : '';
          const newMeal = newItems['features'] ? newItems['features'][id] : '';
          const newEstablishmentType = newItems['establishmentTypes'] ? newItems['establishmentTypes'][id] : '';
          const newFood = newItems['foods'] ? newItems['foods'][id] : '';
          const newMainCuisine = newItems['mainCuisines'] ? newItems['mainCuisines'][id] : '';
          const newSubCuisine = newItems['subCuisines'] ? newItems['subCuisines'][id] : '';

          return (
            <tr key={id}>
              <td>{id}</td>
              <td>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                  <Input value={newName} onChange={(event) => handleChangeName(id, event.target.value)} />
                </div>
                <div style={{ display: 'flex', justifyContent: 'center', marginTop: '10px' }}>
                  <button onClick={() => handleUpdateName(id)}>Update</button>
                </div>
              </td>

              <td>
                {(beverages || []).map((beverage) => (
                  <div key={beverage} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <span>{beverage}</span>
                    <button className="remove-button" onClick={() => handleRemoveItem(id, 'beverages', beverage)}>
                      ❌
                    </button>
                  </div>
                ))}
              </td>

              <td>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                  <select value={newBeverage} onChange={(event) => handleChangeNewItem(id, 'beverages', event.target.value)}>
                    <option value="">Select Beverage</option>
                    {beveragesOptions.map((beverageOption) => (
                      <option key={beverageOption.value} value={beverageOption.value}>
                        {beverageOption.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                  <button onClick={() => handleAddItem(id, 'beverages')}>Add</button>
                </div>
              </td>

              <td>
                {(establishmentTypes || []).map((establishmentType) => (
                  <div key={establishmentType} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <span>{establishmentType}</span>
                    <button className="remove-button" onClick={() => handleRemoveItem(id, 'establishmentTypes', establishmentType)}>
                      ❌
                    </button>
                  </div>
                ))}
              </td>

              <td>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                  <select value={newEstablishmentType} onChange={(event) => handleChangeNewItem(id, 'establishmentTypes', event.target.value)}>
                    <option value="">Select Establishment Type</option>
                    {establishmentTypesOptions.map((establishmentTypeOption) => (
                      <option key={establishmentTypeOption.value} value={establishmentTypeOption.value}>
                        {establishmentTypeOption.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div style={{ display: 'flex', justifyContent: 'center', marginTop: '10px' }}>
                  <button onClick={() => handleAddItem(id, 'establishmentTypes')}>Add</button>
                </div>
              </td>

              <td>
                {features.filter(feature =>
                  featuresOptions.some(option => option.value === feature && option.key === "feature")
                ).map((features) => (
                  <div key={features} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <span>{features}</span>
                    <button className="remove-button" onClick={() => handleRemoveItem(id, 'features', features)}>
                      ❌
                    </button>
                  </div>
                ))}
              </td>

              <td>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                  <select value={newFeature} onChange={(event) => handleChangeNewItem(id, 'features', event.target.value)}>
                    <option value="">Select Feature</option>
                    {featuresOptions.filter(featureOption => featureOption.key === "feature").map((featureOption) => (
                      <option key={featureOption.value} value={featureOption.value}>
                        {featureOption.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div style={{ display: 'flex', justifyContent: 'center', marginTop: '10px' }}>
                  <button onClick={() => handleAddItem(id, 'features')}>Add</button>
                </div>
              </td>

              <td>
                {features.filter(feature =>
                  featuresOptions.some(option => option.value === feature && option.key === "style")
                ).map((styles) => (
                  <div key={styles} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <span>{styles}</span>
                    <button className="remove-button" onClick={() => handleRemoveItem(id, 'features', styles)}>
                      ❌
                    </button>
                  </div>
                ))}
              </td>

              <td>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                  <select value={newStyle} onChange={(event) => handleChangeNewItem(id, 'styles', event.target.value)}>
                    <option value="">Select Style</option>
                    {featuresOptions.filter(featureOption => featureOption.key === "style").map((featureOption) => (
                      <option key={featureOption.value} value={featureOption.value}>
                        {featureOption.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                  <button onClick={() => handleAddItem(id, 'styles')}>Add</button>
                </div>
              </td>

              <td>
                {features.filter(feature =>
                  featuresOptions.some(option => option.value === feature && option.key === "goodFor")
                ).map((goodFors) => (
                  <div key={goodFors} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <span>{goodFors}</span>
                    <button className="remove-button" onClick={() => handleRemoveItem(id, 'features', goodFors)}>
                      ❌
                    </button>
                  </div>
                ))}
              </td>

              <td>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                  <select value={newGoodFor} onChange={(event) => handleChangeNewItem(id, 'goodFors', event.target.value)}>
                    <option value="">Select Good For</option>
                    {featuresOptions.filter(featureOption => featureOption.key === "goodFor").map((featureOption) => (
                      <option key={featureOption.value} value={featureOption.value}>
                        {featureOption.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                  <button onClick={() => handleAddItem(id, 'goodFors')}>Add</button>
                </div>
              </td>

              <td>
                {features.filter(feature =>
                  featuresOptions.some(option => option.value === feature && option.key === "mood")
                ).map((moods) => (
                  <div key={moods} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <span>{moods}</span>
                    <button className="remove-button" onClick={() => handleRemoveItem(id, 'features', moods)}>
                      ❌
                    </button>
                  </div>
                ))}
              </td>

              <td>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                  <select value={newMood} onChange={(event) => handleChangeNewItem(id, 'moods', event.target.value)}>
                    <option value="">Select Mood</option>
                    {featuresOptions.filter(featureOption => featureOption.key === "mood").map((featureOption) => (
                      <option key={featureOption.value} value={featureOption.value}>
                        {featureOption.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                  <button onClick={() => handleAddItem(id, 'moods')}>Add</button>
                </div>
              </td>

              <td>
                {features.filter(feature =>
                  featuresOptions.some(option => option.value === feature && option.key === "meal")
                ).map((meals) => (
                  <div key={meals} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <span>{meals}</span>
                    <button className="remove-button" onClick={() => handleRemoveItem(id, 'features', meals)}>
                      ❌
                    </button>
                  </div>
                ))}
              </td>

              <td>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                  <select value={newMeal} onChange={(event) => handleChangeNewItem(id, 'meals', event.target.value)}>
                    <option value="">Select Meal</option>
                    {featuresOptions.filter(featureOption => featureOption.key === "meal").map((featureOption) => (
                      <option key={featureOption.value} value={featureOption.value}>
                        {featureOption.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                  <button onClick={() => handleAddItem(id, 'meals')}>Add</button>
                </div>
              </td>

              <td>
                {(foods || []).map((food) => (
                  <div key={food} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <span>{food}</span>
                    <button className="remove-button" onClick={() => handleRemoveItem(id, 'foods', food)}>
                      ❌
                    </button>
                  </div>
                ))}
              </td>

              <td>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                  <select value={newFood} onChange={(event) => handleChangeNewItem(id, 'foods', event.target.value)}>
                    <option value="">Select Food</option>
                    {foodsOptions.map((foodOption) => (
                      <option key={foodOption.value} value={foodOption.value}>
                        {foodOption.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div style={{ display: 'flex', justifyContent: 'center', marginTop: '10px' }}>
                  <button onClick={() => handleAddItem(id, 'foods')}>Add</button>
                </div>
              </td>

              <td>
                {(mainCuisines || []).map((mainCuisine) => (
                  <div key={mainCuisine} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <span>{mainCuisine}</span>
                    <button className="remove-button" onClick={() => handleRemoveItem(id, 'mainCuisines', mainCuisine)}>
                      ❌
                    </button>
                  </div>
                ))}
              </td>

              <td>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                  <select value={newMainCuisine} onChange={(event) => handleChangeNewItem(id, 'mainCuisines', event.target.value)}>
                    <option value="">Select Main Cuisine</option>
                    {mainCuisinesOptions.map((mainCuisineOption) => (
                      <option key={mainCuisineOption.value} value={mainCuisineOption.value}>
                        {mainCuisineOption.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div style={{ display: 'flex', justifyContent: 'center', marginTop: '10px' }}>
                  <button onClick={() => handleAddItem(id, 'mainCuisines')}>Add</button>
                </div>
              </td>

              <td>
                {(subCuisines || []).map((subCuisine) => (
                  <div key={subCuisine} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <span>{subCuisine}</span>
                    <button className="remove-button" onClick={() => handleRemoveItem(id, 'subCuisines', subCuisine)}>
                      ❌
                    </button>
                  </div>
                ))}
              </td>

              <td>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                  <select value={newSubCuisine} onChange={(event) => handleChangeNewItem(id, 'subCuisines', event.target.value)}>
                    <option value="">Select Sub Cuisine</option>
                    {subCuisinesOptions.map((subCuisineOption) => (
                      <option key={subCuisineOption.value} value={subCuisineOption.value}>
                        {subCuisineOption.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div style={{ display: 'flex', justifyContent: 'center', marginTop: '10px' }}>
                  <button onClick={() => handleAddItem(id, 'subCuisines')}>Add</button>
                </div>
              </td>

              <td className="no-wrap">
                {servicesOrder.map(service => (
                  <div key={service}>
                    <label>
                      <input
                        type="checkbox"
                        checked={services && services[service]}
                        onChange={() => handleToggleService(id, service)}
                      />
                      {service}
                    </label>
                  </div>
                ))}
              </td>

              <td className="no-wrap">
                {['isVegan', 'isVegetarian', 'isGlutenFree'].map(diet => (
                  <div key={diet}>
                    <label>
                      <input
                        type="checkbox"
                        checked={dietaryRestrictions && dietaryRestrictions[diet]}
                        onChange={() => handleToggleDietRestriction(id, diet)}
                      />
                      {diet}
                    </label>
                  </div>
                ))}
              </td>

              <td className="no-wrap">
                {images[id] && (
                  <ul>
                    {[...new Map(images[id].map(url => {
                      const urlObj = new URL(url);
                      const pathnameArr = urlObj.pathname.split('/');
                      const filenameWithPath = decodeURIComponent(pathnameArr[pathnameArr.length - 1]);
                      const filename = filenameWithPath.split('/').pop();
                      return [filename, url];
                    })).entries()].map(([filename, url], index) => (
                      <li key={index}>
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                          <a href={url} target="_blank" rel="noopener noreferrer">{filename}</a>
                          <button className="remove-button" onClick={() => handleDeleteImage(id, url)}>❌</button>
                        </div>
                      </li>
                    ))}
                  </ul>
                )}
              </td>

              <td>
                <input
                  type="file"
                  key={inputKey}
                  onChange={(e) => handleFileChange(e, place.id)}
                  disabled={uploadingImage[place.id]}
                />

                {uploadingImage[place.id] && <p>Uploading image... ({uploadProgress[place.id] ? uploadProgress[place.id].toFixed(2) : 0}%)</p>}
              </td>
            </tr>
          );
        })}
      </tbody>
    </Table>
  );
}

PlacesTable.propTypes = {
  places: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      // other properties of place...
    })
  ).isRequired,
  foods: PropTypes.array.isRequired,
  beverages: PropTypes.array.isRequired,
  features: PropTypes.array.isRequired,
  establishmentTypes: PropTypes.array.isRequired,
  mainCuisines: PropTypes.array.isRequired,
  subCuisines: PropTypes.array.isRequired,
};

export default PlacesTable;