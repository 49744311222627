import { useState, useEffect } from 'react';
import { getAuth, onAuthStateChanged, GoogleAuthProvider, signInWithPopup } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import { getStorage, ref, getDownloadURL } from "firebase/storage";

import './App.css';

function HomePage() {
    const [user, setUser] = useState(null);

    const navigate = useNavigate();

    const handleUpdate = () => {
        navigate('/update');
    };
    
    const handleCreate = () => {
        navigate('/create');
    };
    
    const handleShow = () => {
        navigate('/show');
    };

    useEffect(() => {
        const auth = getAuth();
        onAuthStateChanged(auth, setUser);
    }, []);

    const signInWithGoogle = async () => {
        const provider = new GoogleAuthProvider();
        const auth = getAuth();
        
        try {
            const result = await signInWithPopup(auth, provider);
            const loggedInUser = result.user;
    
            // Fetch the users.json after successful login
            const storage = getStorage();
            const configRef = ref(storage, 'config/FirestoreManager/users.json');
            
            try {
                const url = await getDownloadURL(configRef);
                const response = await fetch(url);
                const usersData = await response.json();
    
                // Find the user in the fetched users array
                const userConfig = usersData.find(user => user.email === loggedInUser.email);
                
                if (userConfig) {
                    if (userConfig.roles.includes('admin') || userConfig.roles.includes('collaborator')) {
                        setUser(loggedInUser);
                    } else {
                        // Log out the user immediately if they don't have the correct role
                        auth.signOut();
                        console.error("Unauthorized role");
                    }
                } else {
                    // Log out the user immediately if they are not found in the whitelist
                    auth.signOut();
                    console.error("User not found in whitelist");
                }
    
            } catch (error) {
                // Handle the specific case when users.json is missing or any other error during fetch
                console.error("Error fetching users.json:", error);
    
                // Default behavior (logging out the user in this case)
                auth.signOut();
                console.error("Logged out due to missing or inaccessible users.json");
            }
    
        } catch (error) {
            console.error("Error during Google sign-in:", error);
        }
    };    
    
    const logout = () => {
        const auth = getAuth();
        auth.signOut()
            .then(() => {
                setUser(null);
                console.log("Logged out successfully");
            })
            .catch(error => {
                console.error("Error logging out:", error);
            });
    }

    const [isHovered, setIsHovered] = useState(false);

    const handleMouseEnter = () => {
        setIsHovered(true);
    };

    const handleMouseLeave = () => {
        setIsHovered(false);
    };

    return (
        <div className="App">
            {user ? (
                <div className="signin-container">
                    <button onClick={handleUpdate}>Update Collections</button>
                    <button onClick={handleCreate}>Create New Item</button>
                    <button onClick={handleShow}>Show Dependencies</button>
                    <button onClick={logout}>Logout</button>
                </div>
            ) : (
                <div className="signin-container">
                    <button
                        onClick={signInWithGoogle}
                        onMouseEnter={handleMouseEnter}
                        onMouseLeave={handleMouseLeave}
                        className={isHovered ? 'hovered' : ''}
                    >
                        Sign In with Google
                    </button>
                </div>
            )}
        </div>
    );
}

export default HomePage;
