import React from 'react';

import './ShowDependencies.css'; // Import your CSS file for styling

function ShowDependencies() {
    return (
        <div className="page-content">
            <h1>Show Dependencies</h1>
            {/* Here you can put your logic for showing dependencies */}
        </div>
    );
}

export default ShowDependencies;
