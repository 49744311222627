import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Homepage from './Homepage';
import UpdateCollection from './UpdateCollection';
import CreateNewItem from './CreateNewItem';
import ShowDependencies from './ShowDependencies';

function App() {
    return (
        <Router>
            <div className="App">
                <Routes>
                    <Route path="/" element={<Homepage />} />
                    <Route path="/update" element={<UpdateCollection />} />
                    <Route path="/create" element={<CreateNewItem />} />
                    <Route path="/show" element={<ShowDependencies />} />
                </Routes>
            </div>
        </Router>
    );
}

export default App;
