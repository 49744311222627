// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getAuth, GoogleAuthProvider, signInWithPopup } from 'firebase/auth';
// import { getAuth, onAuthStateChanged, GoogleAuthProvider, signInWithPopup } from 'firebase/auth';
import { getFirestore, GeoPoint } from 'firebase/firestore';
// import { getFirestore, getDocs, collection } from 'firebase/firestore';

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDaeNYgoE_ES0yvCe_wAUOI8jAKHqBDOFk",
  authDomain: "portami-6c80c.firebaseapp.com",
  projectId: "portami-6c80c",
  storageBucket: "portami-6c80c.appspot.com",
  messagingSenderId: "666433066918",
  appId: "1:666433066918:web:3f92bd1bc3175c3f63a167",
  measurementId: "G-V4X7Q7S2JT"
};

// Initialize Firebase
initializeApp(firebaseConfig);

// Get a reference to the Firestore service
const db = getFirestore();

// Get a reference to the Auth service
const auth = getAuth();

export { auth, db, GoogleAuthProvider, signInWithPopup, GeoPoint};