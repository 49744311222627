import { useState, useEffect } from 'react';
import { getDocs, collection, query, orderBy, startAfter, limit } from 'firebase/firestore';
import { db } from './firebase';
import PlacesTable from './PlacesTable';

import './UpdateCollections.css'; // Import your CSS file for styling

function UpdateCollection() {
  const [places, setPlaces] = useState([]);
  const [loading, setLoading] = useState(false);
  const [documentsCount, setDocumentsCount] = useState({
    places: 0,
    foods: 0,
    beverages: 0,
    features: 0,
    establishmentTypes: 0,
    mainCuisines: 0,
    subCuisines: 0,
  });
  const placesPerPage = 10; // Number of places to fetch per page
  const [currentPage, setCurrentPage] = useState(1);
  const [pagesData, setPagesData] = useState({});
  const [maxPages, setMaxPages] = useState(null); // Initialize to null
  const [sortOrder] = useState('desc'); // Initialize sort order to 'desc'

  const fetchPlaces = async (page) => {
    setLoading(true);

    if (maxPages === null) {
      // log the total documents inside "places" collection
      const totalDocuments = await getDocs(collection(db, 'places'));
      console.log('Total documents inside "places" collection:', totalDocuments.docs.length);
      setMaxPages(Math.ceil(totalDocuments.docs.length / placesPerPage)); // Add this line
    }

    if (pagesData[page]) {
      // Data for this page is already fetched
      setPlaces(pagesData[page].places);
      setLoading(false);
    } else {
      // Need to fetch data for this page
      let placesQuery = query(
        collection(db, 'places'),
        orderBy('createdDate', sortOrder), // Make sure to have an index on this field in Firestore
        limit(placesPerPage)
      );

      if (pagesData[page - 1]) {
        // Not the first page, start after last document of previous page
        placesQuery = query(placesQuery, startAfter(pagesData[page - 1].lastDocument));
      }

      const snapshot = await getDocs(placesQuery);
      const fetchedPlaces = snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));

      setPlaces(fetchedPlaces);
      setPagesData(prevPagesData => ({
        ...prevPagesData,
        [page]: {
          places: fetchedPlaces,
          lastDocument: snapshot.docs[snapshot.docs.length - 1],
        },
      }));
      setLoading(false);
      setDocumentsCount(prevCount => ({ ...prevCount, places: fetchedPlaces.length }));
    }
  };

  const fetchCollectionData = async (collectionName) => {
    try {
      const snapshot = await getDocs(collection(db, collectionName));
      const fetchedData = snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
      localStorage.setItem(collectionName, JSON.stringify(fetchedData));
      setDocumentsCount(prevCount => ({ ...prevCount, [collectionName]: fetchedData.length }));

      console.log(`Fetched "${collectionName}" collection`);
      console.log(`"${collectionName}" collection size:`, fetchedData.length);
    } catch (error) {
      console.error(`Error fetching "${collectionName}" collection:`, error);
    }
  };

  useEffect(() => {
    fetchPlaces(currentPage);

    // Fetch other collections if needed
    ['foods', 'beverages', 'features', 'establishmentTypes', 'mainCuisines', 'subCuisines'].forEach(collectionName => {
      const cachedData = localStorage.getItem(collectionName);
      if (cachedData) {
        setDocumentsCount(prevCount => ({ ...prevCount, [collectionName]: JSON.parse(cachedData).length }));
      } else {
        fetchCollectionData(collectionName);
      }
    });
  }, [currentPage]); // Fetch places and other collections when the current page changes

  useEffect(() => {
    const totalDocumentsRead = Object.values(documentsCount).reduce((a, b) => a + b, 0);
    console.log('Total documents read:', totalDocumentsRead);
  }, [documentsCount]);

  return (
    <div className="page-content">
      <PlacesTable
        places={places}
        foods={JSON.parse(localStorage.getItem('foods')) || []}
        beverages={JSON.parse(localStorage.getItem('beverages')) || []}
        features={JSON.parse(localStorage.getItem('features')) || []}
        establishmentTypes={JSON.parse(localStorage.getItem('establishmentTypes')) || []}
        mainCuisines={JSON.parse(localStorage.getItem('mainCuisines')) || []}
        subCuisines={JSON.parse(localStorage.getItem('subCuisines')) || []}
      />
      {loading && <p>Loading...</p>}
      {!loading && places.length > 0 && (
        <div>
          {currentPage > 1 && (
            <button onClick={() => setCurrentPage(prevPage => prevPage - 1)}>
              Previous
            </button>
          )}
          <span>Page {currentPage} of {maxPages}</span>
          {currentPage !== maxPages && (
            <button onClick={() => setCurrentPage(prevPage => prevPage + 1)}>
              Next
            </button>
          )}
        </div>
      )}
    </div>);
}

export default UpdateCollection;