import React, { useState } from 'react';
import request from 'superagent';
import './CreateNewItem.css';
import { db, GeoPoint} from './firebase';
import { doc, getDoc, setDoc } from "firebase/firestore";

function CreateNewItem() {
  const [placeName, setPlaceName] = useState('');
  const [placeData, setPlaceData] = useState(null);
  const [status, setStatus] = useState('');

  const handleInputChange = (event) => {
    setPlaceName(event.target.value);
  };

  const sanitizeAndCleanData = (data) => {
    const { location, ...otherData } = data;
    const { __lat__, __lon__ } = location.latLng;
    const geopoint = new GeoPoint(__lat__, __lon__);

    // Update location with cleaned latLng
    const cleanedLocation = {
      ...location,
      latLng: geopoint,
    };

    // Convert the problematic date field to a Firestore-compatible timestamp
    const sanitizedCreatedDate = data.createdDate && data.createdDate.__time__
      ? new Date(data.createdDate.__time__)
      : data.createdDate;

    // Create a helper function to replace "\u202F" in strings
    const replaceCharInStringValues = (value) => {
      if (typeof value === 'string') {
        return value.replace(/\u202F/g, ' ');
      }
      if (typeof value === 'object' && value !== null) {
        Object.keys(value).forEach(key => {
          value[key] = replaceCharInStringValues(value[key]);
        });
      }
      return value;
    };

    // Use helper function on otherData and cleanedLocation
    const cleanedOtherData = replaceCharInStringValues(otherData);
    const cleanedCleanedLocation = replaceCharInStringValues(cleanedLocation);

    return {
      ...cleanedOtherData,
      location: cleanedCleanedLocation,
      createdDate: sanitizedCreatedDate,
    };
  };

  const handleFormSubmit = async (event) => {
    event.preventDefault();

    if (!placeName) {
      alert('Please enter a place name');
      return;
    }

    let transformedData = await fetchPlaceData();

    if (transformedData) {
      transformedData = sanitizeAndCleanData(transformedData);
      setPlaceData(transformedData);

      const placeId = generatePlaceId(transformedData);

      if (!placeId) {
        setStatus('Error generating place ID. Please check your data.');
        return;
      }

      const placeRef = doc(db, 'places', placeId);

      const docSnap = await getDoc(placeRef);
      if (docSnap.exists()) {
        setStatus('The place is already on Firestore');
      } else {
        setDoc(placeRef, transformedData)
          .then(() => {
            setStatus('Data saved to Firestore!');
          })
          .catch((error) => {
            setStatus('Error saving data to Firestore: ' + error.message);
          });
      }
    }
  };

  const fetchPlaceData = async () => {
    try {
      const response = await request.get(
        `https://europe-west1-portami-6c80c.cloudfunctions.net/saveNewPlaceAPI/place?placeName=${encodeURIComponent(placeName)}`
        // `http://localhost:5002/place?placeName=${encodeURIComponent(placeName)}`
      );
      console.log("fetchPlaceData response: ", response.body);
      return response.body;
    } catch (error) {
      console.error('Error fetching place data:', error);
      return null;
    }
  };

  const generatePlaceId = (data) => {
    const { name, location } = data;
    const { countryCode, localityCode } = location || {};

    if (!countryCode || !localityCode) {
      console.warn('Missing countryCode or localityCode in data:', data);
      return null;
    }

    // Clean up the name by removing unwanted characters
    const cleanName = name.normalize("NFD").replace(/[\u0300-\u036f]/g, "").replace(/[^a-zA-Z0-9.%!? ]/g, '');

    // Split the cleaned-up name into words by space or special characters other than '%'
    const words = cleanName.split(/[\s.!?-]+/);

    for (let i = 0; i < words.length; i++) {
      let word = words[i];
      if (word.includes('%') || word.includes('!') || word.includes('?')) {
        // If a word contains '%', split it, capitalize the part after '%', and then rejoin it
        let subWords = word.split('%');
        subWords[1] = subWords[1].charAt(0).toUpperCase() + subWords[1].slice(1);
        word = subWords.join('%');
      }

      if (word.match(/[a-zA-Z]/)) {  // Check if the word contains any letter
        words[i] = word.charAt(0).toUpperCase() + word.slice(1);
      }
    }

    // Join the words together without spaces
    const formattedName = words.join('');

    console.log("formattedName: ", formattedName);

    return `${countryCode}-${localityCode}-${formattedName}`;
  };

  return (
    <div className="page-content">
      <h1>Save New Place</h1>

      <form onSubmit={handleFormSubmit} className="create-form">
        <input type="text" value={placeName} onChange={handleInputChange} placeholder="Enter place name..." />
        <button type="submit">Save New Place</button>
      </form>

      {status && <div className={`status-message ${status.startsWith('Error') ? 'error' : status.startsWith('The place is already') ? 'warning' : 'success'}`}>{status}</div>}

      {placeData ? (
        <>
          <h2>Place Data</h2>
          <table>
            <thead>
              <tr>
                <th>Property</th>
                <th>Value</th>
              </tr>
            </thead>
            <tbody>
              {Object.entries(placeData).map(([key, value]) => (
                <tr key={key}>
                  <td>{key}</td>
                  <td>
                    {typeof value === 'object' && value !== null ?
                      JSON.stringify(value) :
                      value
                    }
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </>
      ) : (
        <p>No data yet, please fetch a place...</p>
      )}
    </div>
  );
}

export default CreateNewItem;